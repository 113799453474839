<template>
  <div class="relative flex items-start justify-center">
    <i
      class="i-ph-magnifying-glass-duotone z-1 text-primary scale-40 block h-full w-full transform"
    />
    <svg
      class="absolute inset-0 z-0 h-full w-full"
      fill="none"
      viewBox="0 0 46 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M33.8072 8.82635C40.8895 10.0311 44.7414 14.8083 45.2431 19.7134C45.6496 23.6859 43.8584 27.7416 39.8042 30.0513C38.1423 30.9985 36.2616 31.443 34.2778 31.8352C27.7994 33.1174 28.1592 32.7707 23.4759 37.0143C16.2652 43.5483 3.20714 38.6074 0.560138 29.448C-1.01912 23.9842 0.42205 17.1732 9.02335 11.8556C14.4029 8.52904 12.3754 0.675109 19.8039 0.031106C21.8538 -0.146116 24.0117 0.431066 25.4548 1.83625C27.8101 4.12852 28.4801 7.92087 33.8072 8.82635Z"
        fill="#E8EAF7"
        fill-rule="evenodd"
      />
    </svg>
  </div>
</template>
